'use client';

import type { NextPage } from 'next';
import type { PropsWithChildren, ReactElement, ReactNode } from 'react';

export type NextPageWithLayout<TProps = Record<string, unknown>> =
  NextPage<TProps> & {
    getLayout?: (page: ReactElement) => ReactNode;
    /**
     * Require MFA to be set up before rendering the page
     * Is `true` of any `/org/*` page, and `false` for all other pages
     */
    requireMfaIfLoggedIn?: boolean;
    /**
     * Require login before rendering the page
     */
    requireLogin?: boolean;
  };

export const Single: React.FC<PropsWithChildren> = (props) => {
  return (
    <div
      className="flex h-fill min-h-fill w-full flex-col text-foreground"
      {...props}
    >
      {props.children}
    </div>
  );
};
