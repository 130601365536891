import type { NextPageWithLayout } from '~/modules/ui/layouts/single';
import { Single } from '~/modules/ui/layouts/single';
import { NotFound } from '~/modules/ui/not-found';

const NotFoundPage: NextPageWithLayout = () => {
  return <NotFound />;
};

NotFoundPage.getLayout = (page) => {
  return <Single>{page}</Single>;
};

export default NotFoundPage;
