/**
 * @see https://nextjs.org/docs/app/api-reference/file-conventions/not-found
 */

import Link from 'next/link';
import { HtmlTitle } from '~/modules/ui/components/html-title';
import { Single } from '~/modules/ui/layouts/single';
import { ArrowLeftIcon } from '~/modules/ui/primitives/icon';
import { HStack, VStack } from '~/modules/ui/primitives/stack';

function NotFound() {
  return (
    <Single>
      <div className="flex h-screen items-center justify-center">
        <HtmlTitle>Not found :(</HtmlTitle>
        <VStack gap="4">
          <span className="monospace text-title">Page not found :(</span>
          <Link href="/org" className="block">
            <HStack
              gap="1"
              className="monospace cursor-pointer text-subheadline underline transition-colors hover:text-gray-500"
            >
              <ArrowLeftIcon className="mt-0.5" /> Return to home
            </HStack>
          </Link>
        </VStack>
      </div>
    </Single>
  );
}

export { NotFound };
